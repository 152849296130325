  import React from 'react'
  import Layout from '../../components/App/Layout'
  import Navbar from '../../components/App/NavbarRV'
  import Footer from '../../components/App/FooterRV'
  import KDPImagePage from '../../assets/images/pages/5-0-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li>Qui sommes nous ?</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                


<h1>Présentation du cabinet RV EXPERTISES</h1>
<p>L’Expert d’Assuré Dans Votre Région PACA, Aix, Marseille, spécialisé dans vos problématiques de fissures sur ouvrage.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Qui sommes nous ?" />
</div>

<h2>Un cabinet d’expertise indépendant au service de l’assuré</h2>

<p>Créé en 2018, le cabinet RV EXPERTISES est un cabinet d’Experts d’assuré, spécialisé dans l’accompagnement des assurés dans leur problématique de gestion de sinistres.</p>

<p>Créés à l’initiative d’un Expert du bâtiment spécialisé dans les interactions Bâti & Risques Naturels (sécheresse, inondations, tempêtes) et d’un Ingénieur Structure intervenant en tant que sapiteur, le cabinet RV EXPERTISES accompagne les assurés sinistrés dans une gestion post-sinistre afin de défendre leur intérêts.</p>

<p>Le cabinet RV Expertises s’est ainsi spécialisé dans l’accompagnement des sinistrés de la sécheresse, en expertise unilatérale, ou lors des phases d’expertise amiable contradictoire jusqu’en expertise judiciaire. </p>

<h2>Zone d’intervention </h2>

<p>Le cabinet RV EXPERTISES intervient sur toute la zone Sud-est de la France : </p>

<p>Aix-en-Provence, </p>
<p>Marseille, </p>
<p>Nice, Cannes,  </p>
<p>Brignoles, Draguignan, Toulon</p>

<p>ainsi que dans le département du Vaucluse, Hautes-Alpes et Alpes de Haute-Provence</p>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/qui/' className='active'>Qui sommes nous ?</a></li><li><a href='/qui/equipe/' >L'équipe</a></li><li><a href='/qui/references/' >Référence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details